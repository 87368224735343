<template>
    <div id="setUPPackages">
        <div class="setUPPackages_Top">
            <el-button type="primary" size="small" @click="addGift()">新增礼包内容</el-button>
        </div>
        <el-table :data="tableData" style="width: 100%;" max-height="800px">
            <el-table-column prop="title" label="礼物名称" align="center"></el-table-column>
            <el-table-column prop="num" label="数量" align="center"></el-table-column>
            <el-table-column prop="status" label="状态" align="center">
                <template slot-scope="scope">
                    <div>
                        <span style="color:#FF1D1D" v-if="scope.row.status == 0">未开启</span>
                        <span style="color:#07C17E" v-if="scope.row.status == 1">使用中</span>
                    </div>
                </template>
            </el-table-column>
            <el-table-column prop="name" label="操作" align="right" width="120">
                <template slot-scope="scope">
                    <el-button type="text" @click="editGift(scope.row)">编辑</el-button>
                    <el-button type="text" style="color:#FE2F2F" @click="deltetGift(scope.row)">删除</el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog :title="title" :visible.sync="dialogFormVisible" width="600px">
            <el-form :model="form" label-width="120px">
                <el-form-item label="礼物名称">
                    <el-input v-model="form.title" size="small"></el-input>
                </el-form-item>
                <el-form-item label="礼物内容">
                    <div>
                        <el-select size="small" style="width: 150px;" placeholder="请选择礼物类型" v-model="form.type">
                            <el-option v-for="item in gift_options" :key="item.value" :label="item.name"
                                :value="item.id">
                            </el-option>
                        </el-select>
                        <el-select v-model="form.product_id" size="small" v-if="form.type == 1"
                            style="width: 150px;margin-left: 10px;" placeholder="请选择优惠券">
                            <!-- <el-option label="区域一" value="shanghai"></el-option>
                            <el-option label="区域二" value="beijing"></el-option> -->
                        </el-select>
                    </div>
                </el-form-item>
                <el-form-item label="数量">
                    <el-input v-model="form.num" style="width: 150px;" size="small"></el-input>
                </el-form-item>
                <el-form-item label="状态">
                    <el-radio-group v-model="form.status">
                        <el-radio :label="1">开启</el-radio>
                        <el-radio :label="0">关闭</el-radio>
                    </el-radio-group>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="dialogFormVisible = false">取 消</el-button>
                <el-button type="primary" @click="submit">保 存</el-button>
            </div>
        </el-dialog>
    </div>
</template>

<script>

export default {
    data() {
        return {
            gift_id: 0,//礼包ID
            gift_options: [],//礼物类型
            tableData: [],//礼物列表
            title: '新增礼包内容',
            dialogFormVisible: false,
            form: {
                title: '',//礼物名称
                type: '',//礼物类型
                num: '',//数量
                status: 1,
                product_id: null,//	产品id （优惠劵id或者商品id）
            },
        }
    },
    created() {
        this.getList()
        this.getGift()
        this.gift_id = this.$route.query.id
    },
    computed: {},
    methods: {
        // 保存 新增/编辑
        submit() {
            console.log(this.form)
            if (this.title == '新增礼包内容') {
                let data = {
                    gift_id: this.gift_id,
                    title: this.form.title,
                    type: this.form.type,
                    num: this.form.num,
                    product_id: this.form.product_id
                    ,
                }
                this.my_request('admin/member/gift/list', data, 'POST').then(res => {
                    console.log('编辑礼物结果', res);
                    let { code, data } = res.data
                    if (200 == code) {
                        this.$message.success('添加成功！')
                        this.getGift()
                        this.dialogFormVisible = false
                    } else {
                        this.$message.error('添加失败')
                    }
                })
            } else if (this.title == '编辑礼包内容') {
                let data = {
                    ...this.form,
                }
                this.my_request('admin/member/gift/list', data, 'PUT').then(res => {
                    console.log('编辑礼物结果', res);
                    let { code, data } = res.data
                    if (200 == code) {
                        this.$message.success('修改成功！')
                        this.getGift()
                        this.dialogFormVisible = false
                    } else {
                        this.$message.error('修改失败')
                    }
                })
            }
        },
        // 删除
        deltetGift(row) {
            console.log('row', row)
            this.$confirm(`确认要删除礼物'${row.title}'吗?`, '删除礼物', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(() => {
                let data = {
                    id: row.id
                }
                this.my_request('admin/member/gift/list', data, 'DELETE').then(res => {
                    console.log('删除礼物结果', res);
                    let { code } = res.data
                    if (200 == code) {
                        this.$message.success('删除成功！')
                        this.getGift()
                    } else {
                        this.$message.error('删除失败')
                    }
                })
            }).catch(() => {
                this.$message.info('取消删除');
            });
        },
        // 添加
        addGift(row) {
            this.form = {
                title: '',//礼物名称
                type: '',//礼物类型
                num: '',//数量
                status: 1,
            }
            this.title = '新增礼包内容'
            this.dialogFormVisible = true
        },
        // 编辑
        editGift(row) {
            this.getShow(row.id)
            this.title = '编辑礼包内容'
            this.dialogFormVisible = true

        },
        // 获取礼物详情
        getShow(id) {
            let data = {
                id,
            }
            this.my_request('admin/member/gift/list/show', data, 'GET').then(res => {
                console.log('获取礼物详情结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.form = data
                }
            })
        },
        // 获取礼物列表
        getGift() {
            let data = {
                gift_id: this.$route.query.id
            }
            this.my_request('admin/member/gift/list', data, 'GET').then(res => {
                console.log('获取礼物列表结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.tableData = data
                }
            })
        },
        // 获取礼物类型
        getList() {
            this.my_request('admin/member/gift/list/type').then(res => {
                console.log('获取礼物类型结果', res);
                let { code, data } = res.data
                if (200 == code) {
                    this.gift_options = data
                }
            })
        },
    }
}

</script>

<style lang="scss" scoped>
.setUPPackages_Top {
    margin: 10px 0 40px 0;
}


.setUPPackages_content {
    margin: 0 0 0 50px;
    width: 900px;
    font-size: 15px;

    .setUPPackages_content_one {
        display: flex;

        div:nth-child(2) {
            margin: 0 50px;
        }

        span {
            margin: 0 8px;
        }
    }

    .setUPPackages_content_two {
        margin: 20px 0;

        span {
            margin: 0 8px;
        }

        .setUPPackages_content_two_select {
            display: flex;
            margin: 20px 0 0 20px;

            .setUPPackages_content_two_selectItem {
                margin-right: 20px;
                position: relative;
                width: 150px;

                i {
                    position: absolute;
                    right: -5px;
                    top: -5px;
                }
            }
        }
    }

    .setUPPackages_content_button {
        display: flex;
        justify-content: flex-end;
    }
}
</style>